/* eslint-disable  */

export default {
  code: 'Code',
  'Reset Password Again': 'Still Not Recevied? Start Again ?',
  'View All Orders': 'View All Orders',
  'Edit My Profile': 'Edit My Profile',
  'View Address Book': 'View Address Book',
  'View Wishlist': 'View Wishlist',
  'Add to bag': 'Add to cart',
  'Move to bag': 'Move to cart',
  'View Details': 'View Details',
  'You currently have no orders': "You currently have no orders. When you've placed an order, you'll find all the details here.",
  'Buy Again': 'Buy Again',
  Qty: 'Qty',
  'Start shopping': 'Shop Unineed',
  Total: 'Total',
  'product details': 'product details',
  wishlist: 'wishlist',
  'payment method': 'payment method',
  profile: 'profile',
  Order: 'Order',
  Unsubscribe: 'Unsubscribe',
  Subscribe: 'Subscribe',
  'You Have Subscribed': 'You are currently subscribed to receive our exclusive deals newsletter',
  "You haven't subscribed": 'Subscribe to our newsletter for exclusive deals',
  'Edit Address': 'Edit Address',
  'Add Address': 'Add Address',
  'Default Delivery Address': 'Default Delivery Address',
  'Default Billing Address': 'Default Billing Address',
  'No Address': 'You currently don’t have any saved address. Add an address here to be prefilled for quicker checkout.',
  'No Payment': "You currently don't have any saved payment methods. Add a method here to be prefilled for quicker checkout.",
  'Store Credit Balance': 'Store Credit Balance',
  'New Arrivals': 'New Arrivals',
  'Reduced Products': 'Reduced Products',
  'Featured Products': 'Featured Products',
  'Shop by category': 'Shop By Category',
  'Get early access': 'Get early access',
  'Subscribe to our newsletter for exclusive deals': 'subscribe to our newsletter for exclusive deals',
  'Customer Services': 'Customer Services',
  'Delivery & Shipping': 'Delivery & Shipping',
  'Track Your Parcel': 'Track Your Parcel',
  'Return & Refund Policy': 'Return & Refund Policy',
  'My Account': 'My Account',
  'About Us': 'About us',
  'Affiliate Signup': 'Affiliate Signup',
  Legal: 'Legal',
  'Terms & Conditions': 'Terms & Conditions',
  'Privacy Statement': 'Privacy Statement',
  Cookies: 'Cookies',
  DMCA: 'DMCA',
  'you may also like': 'you may also like',
  'Your WishList': 'Your WishList',
  'Recent Viewed Products': 'Recent Viewed Products',
  Checkout: 'Checkout',
  'In Your Bag': 'In Your Bag',
  Subtotal: 'Subtotal',
  'Subtotal(RRP)': 'Subtotal(RRP)',
  'Shipping & Handling': 'Shipping & Handling',
  'Accept Customs Tax Reimbursement Service': 'Accept Customs Tax Reimbursement Service',
  'Grand Total': 'Grand Total',
  Items: 'Items',
  details: 'details',
  Apply: 'Apply',
  Cancel: 'Cancel',
  Available: 'Available',
  'E-mail': 'E-mail',
  'Store Credit': 'Store Credit',
  'Store credit has been applied successfully': 'Store credit has been applied successfully',
  'Store credit has been canceled successfully': 'Store credit has been canceled successfully',
  Summary: 'Summary',
  'Add Discount Code': 'Add Discount Code',
  'Discount code': 'Discount code',
  Delivery: 'Delivery',
  'Delivery Method': 'Delivery Method',
  Payment: 'Payment',
  Billing: 'Billing',
  'Enter new address': 'Enter new address',
  'Place Order': 'Place Order',
  'Select shipping address': 'Select shipping address',
  Edit: 'Edit',
  'Save & Continue': 'Save & Continue',
  NoSearchResultTip: 'We could not find anything',
  'Order detail': 'Order detail',
  'Popular Searches': 'Popular Searches',
  'Recent Searches': 'Recent Searches',
  Suggestions: 'Suggestions',
  'Clear All': 'Clear All',
  'Popular Products': 'Popular Products',
  Search: 'Search',
  'Search for items': 'Search for items',
  'Search Results For': 'Search Results For',
  'Search No Result': "Sorry, We can't find products matching the selection.",
  Brands: 'Brands',
  'Addresses Book': 'Addresses Book',
  'Addresses book': 'Addresses book',
  overview: 'Overview',
  'Sign In': 'Sign In',
  Register: 'Register',
  'Register today': 'Register Today',
  'Reset Password': 'Reset Password',
  'Reset Password Confirmation': 'Reset Password Confirmation',
  'Your Email': 'Your Email',
  Password: 'Password',
  'Forgotten password?': 'Forgotten Password?',
  Login: 'Login',
  'No account': "Don't have an account yet?",
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Sign Up for Newsletter': 'Sign Up for Newsletter',
  'I want to create an account': 'I want to create an account',
  'Create an account': 'Create an account',
  'login in to your account': 'login in to your account',
  forgotPasswordConfirmation:
    'Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.',
  'Reset Password Again': 'Still Not Recevied? Start Again ?',
  'Thank you': 'Thank you',
  'Thank You Inbox': 'If the message is not arriving in your inbox, try another email address you might’ve used to register.',
  RemoveCouponFirst: 'Please remove current applied one, then add a new coupon. ',
  Apply: 'Apply',
  'has used': 'has used',
  Added: 'Added',
  'Login Successfully!': 'Login Successfully!',
  'Must be logged in to view your wish list': 'Must be logged in to view your wish list',
  'Your cart is empty': 'Your cart is empty',
  'You have no items in your shopping bag.': 'You have no items in your shopping bag.',
  'Shop unineed': 'Shop unineed',
  'Copied to Clipboard': 'Copied to Clipboard',
  'Guest checkout': 'Guest checkout',
  'Returning customer checkout': 'Returning customer checkout',
  'Already a customer? Login': 'Already a customer? Login',
  'My Orders': 'My Orders',
  'My Profile': 'My Profile',
  'Log out': 'Log out',
  'Delivery Address': 'Delivery address',
  'Continue to checkout': 'Continue to checkout',
  'store credit': 'store credit',
  'Order history': 'Order history',
  'Update the address': 'Update the address',
  Overview: 'Overview',
  delete: 'delete',
  'Sort by': 'Sort by',
  'Sort: Default': 'Default',
  'Sort: Name A-Z': 'Name A-Z',
  'Sort: Name Z-A': 'Name Z-A',
  All: 'All',
  products: 'products',
  Filters: 'Filters',
  'Clear all': 'Clear all',
  'Apply filters': 'Apply filters',
  Description: 'Description',
  'Read reviews': 'Read reviews',
  'Add to cart': 'Add to cart',
  'Continue to shipping': 'Continue to shipping',
  'Continue to billing': 'Continue to billing',
  'Continue to payment': 'Continue to payment',
  'Add the address': 'Add the address',
  'Set as default shipping': 'Set as default shipping',
  'Set as default billing': 'Set as default billing',
  'House/Apartment number': 'House/Apartment number',
  'Street Name': 'Street Name',
  'Shop Now': 'Shop Now',
  'Add To Cart Failed, please try again': 'Add To Cart Failed, please try again',
  'Your e-mail': 'Your e-mail',
  Orders: 'Orders',
  'My profile': 'My profile',
  'Address book': 'Address book',
  'Order Number': 'Order Number',
  'Full Name': 'Full Name',
  Email: 'Email',
  'Order details': 'Order details',
  'Order No.': 'Order No.',
  'Order Date': 'Order Date',
  'Billing Address': 'Billing Address',
  'Payment Method': "'Payment Method",
  'Need Help': 'Need Help',
  'Call Us': 'Call Us',
  'Available:monday to friday': 'Available:monday to friday',
  'Your Suffix': 'Your Suffix',
  'Personal Details': 'Personal Details',
  'Your store credit': 'Your store credit',
  'Addresses details': 'Addresses details',
  'Update password': 'Update password',
  'Update personal data': 'Update personal data',
  'Sign In Details': 'Sign In Details',
  Newsletter: 'Newsletter',
  'Current Password': 'Current Password',
  'Attention!': 'Attention!',
  'Please type your current password to change your email address.': 'Please type your current password to change your email address.',
  'My newsletter': 'My newsletter',
  'My reviews': 'My reviews',
  'My wishlist': 'My wishlist',
  'Password change': 'Password change',
  'Personal data': 'Personal data',
  'State/Province': 'State/Province',
  'Zip-code': 'Zip-code',
  Country: 'Country',
  City: 'City',
  Street: 'Street',
  'Phone number': 'Phone number',
  'Please select a country first': 'Please select a country first',
  Remove: 'Remove',
  Empty: 'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.',
  'Empty bag': 'Empty bag',
  'Your bag is empty': 'Your bag is empty',
  'Transaction ID': 'Transaction ID',
  date: 'date',
  change: 'change',
  'New Balance': 'New Balance',
  Action: 'Action',
  'Invalid email': 'Invalid email',
  'Follow us': 'Follow us',
  'Contact us': 'Contact us',
  'Head Office': 'Head Office',
  Warehouse: 'Warehouse',
  'Go back': 'Go back',
  'Go back shopping': 'Go back shopping',
  'Go back to shop': 'Go back to shop',
  'Go to checkout': 'Go to checkout',
  'Go to cart': 'Go to cart',
  'This field is required': 'This field is required',
  'The field should have at least 2 characters': 'The field should have at least 2 characters',
  'The field should have at least 4 characters': 'The field should have at least 4 characters',
  'The field should have at least 8 characters': 'The field should have at least 8 characters',
  'My Account': 'My Account',
  'Page not found': 'Sorry, the page or product cannot be viewed, please go back to the homepage!',
  'VIEW BASKET': 'VIEW BASKET',
  'Continue Shopping': 'Continue Shopping',
  'Oops, an error occurred! Try again': 'Oops, an error occurred! Try again',
  // old
  code: 'Code',
  'Thank you for your order!': 'Thank you for your order!',
  'Thank you for shopping with us. Your order is currently being processed.':
    'Thank you for shopping with us. Your order is currently being processed.',
  'Your order number:': 'Your order number:',
  product: 'product',
  Discount: 'Discount',
  'Order-help-call': 'If you have questions about your order, please call us at',
  Or: 'or',
  'Submit an inquiry online': 'submit an inquiry online',
  'Create an account': 'Create an account',
  'Creating an account tips':
    'By creating an account with our store, you will be able to move through the checkout process faster, store multiple shipping addresses, view and track your orders in your account and more.',
  'Add To Cart Successfully': 'Add To Cart Successfully',
  Cart: 'Cart',
  'Your Bag': 'Your Bag',
  'Sold Out': 'Sold Out',
  'Applied Coupon': 'Applied Coupon',
  Account: 'Account',
  'Add new address': 'Add new address',
  'Add to Wishlist': 'Add to Wishlist',
  'Additional Information': 'Additional Information',
  'All Orders': 'All Orders',
  'Allow order notifications': 'Allow order notifications',
  Wishlist: 'Wishlist',
  Home: 'Home',
  Menu: 'Menu',
  'You are not authorized, please log in.': 'You are not authorized, please log in.',
  'No shipping methods tips': 'There are no shipping methods available for this country. We are sorry, please try with different country.',
  'Select this shipping method error tips':
    'There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.',
  'Attention!': 'Attention!',
  'Back to home': 'Back to home',
  'Back to homepage': 'Back to homepage',
  'Billing address': 'Billing address',
  Brand: 'Brand',
  Cancel: 'Cancel',
  Categories: 'Categories',
  Change: 'Change',
  'Change password your account': 'If you want to change the password to access your account, enter the following information',
  Color: 'Color',
  'Commercial information': 'and agree to receive personalized commercial information from Brand name by email',
  'Contact details updated': 'Keep your addresses and contact details updated.',
  'Contact us': 'Contact us',
  'Cookies Policy': 'Cookies Policy',
  'Customer Reviews': 'Customer Reviews',
  'Customer service': 'Customer service',
  Date: 'Date',
  'Default Shipping Address': 'Default Shipping Address',
  Delete: 'Delete',
  Departments: 'Departments',
  'Details and status orders': 'Check the details and status of your orders in the online store. You can also cancel your order or request a return.',
  Done: 'Done',
  Download: 'Download',
  'Download all': 'Download all',
  'Email address': 'Email address',
  'Enjoy your free account': 'Enjoy these perks with your free account!',
  'Enter promo code': 'Enter promo code',
  Feedback: 'Your feedback is important to us. Let us know what we could improve.',
  'Feel free to edit': 'Feel free to edit any of your details below so your account is always up to date',
  'Find out more': 'Find out more',
  'Forgot Password': 'Enter your email to receive instructions on how to reset your password.',
  'Forgot Password Modal Email': 'Email you are using to sign in:',
  forgotPasswordConfirmation:
    'Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.',
  'Forgotten password?': 'Forgotten password?',
  Guarantee: 'Guarantee',
  Help: 'Help',
  'Help & FAQs': 'Help & FAQs',
  hide: 'hide',
  'I agree to': 'I agree to',
  'I confirm subscription': 'I confirm subscription',
  'Info after order':
    'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.',
  Instruction1: 'Take care of me',
  Instruction2: 'Just here for the care instructions?',
  Instruction3: 'Yeah, we thought so',
  'It was not possible to request a new password, please check the entered email address.':
    'It was not possible to request a new password, please check the entered email address.',
  Item: 'Item',
  Items: 'Items',
  Kidswear: 'Kidswear',

  'Let’s start now – we’ll help you': 'Let’s start now – we’ll help you.',
  'Log into your account': 'Log into your account',

  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.',
  'Looks like you haven’t added any items to the Wishlist.': 'Looks like you haven’t added any items to the Wishlist.',
  'Make an order': 'Make an order',
  'Manage addresses':
    'Manage all the addresses you want (work place, home address...) This way you won"t have to enter the address manually with each order.',
  'Manage billing addresses':
    'Manage all the billing addresses you want (work place, home address...) This way you won"t have to enter the billing address manually with each order.',
  'Manage shipping addresses':
    'Manage all the shipping addresses you want (work place, home address...) This way you won"t have to enter the shipping address manually with each order.',
  'Match it with': 'Match it with',
  'Men fashion': 'Men fashion',
  'My billing and shipping address are the same': 'My billing and shipping address are the same',
  'My Cart': 'My Cart',
  or: 'or',
  'or fill the details below': 'or fill the details below',
  'Order ID': 'Order ID',
  'Order information': 'Order information',
  'Order summary': 'Order summary',
  'Other products you might like': 'Other products you might like',
  'Out of stock': 'Out of stock',
  OutOfStock: 'Sorry, this product is out of stock now',
  Password: 'Password',
  'Password Changed': 'Password successfuly changed. You can now go back to homepage and sign in.',
  'Pay for order': 'Pay for order',
  'Payment & Delivery': 'Payment & Delivery',
  'Payment Method': 'Payment Method',
  'Payment Methods': 'Payment Methods',
  'Personal details': 'Personal details',
  Price: 'Price',
  Privacy: 'Privacy',
  'Privacy Policy': 'Privacy Policy',
  Product: 'Product',
  'Product suggestions': 'Product suggestions',
  Products: 'Products',
  'Products found': 'Products found',
  'Purchase terms': 'Purchase terms',
  'Quality in the details': 'Quality in the details',
  Quantity: 'Quantity',
  'Read all reviews': 'Read all reviews',
  'Read and understand': 'I have read and understand the',
  Remove: 'Remove',
  'Remove Address': 'Remove Address',
  'Remove from Wishlist': 'Remove from Wishlist',
  'Repeat Password': 'Repeat Password',
  'Reset Password': 'Reset Password',
  'Review my order': 'Review my order',
  'Same as shipping address': 'Same as shipping address',
  'Save changes': 'Save changes',
  'Save for later': 'Save for later',
  'Save Password': 'Save Password',
  'Search results': 'Search results',
  'Sections that interest you': 'Sections that interest you',
  'See all results': 'See all results',
  'See more': 'See more',
  'Select payment method': 'Select payment method',
  'Select shipping method': 'Select shipping method',
  'Send my feedback': 'Send my feedback',
  'Set up newsletter':
    'Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.',
  'Share your look': 'Share your look',
  Shipping: 'Shipping',
  'Shipping address': 'Shipping address',
  'Shipping details': 'Shipping details',
  'Shipping method': 'Shipping method',
  Show: 'Show',
  'show more': 'show more',
  'Show on page': 'Show on page',
  'Sign in': 'Sign in',
  'Size guide': 'Size guide',
  'Sort: Price from high to low': 'Price from high to low',
  'Sort: Price from low to high': 'Price from low to high',
  Status: 'Status',
  'Subscribe to newsletter': 'Subscribe to newsletter',
  subscribeToNewsletterModalContent:
    'After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.',
  'Subtotal price': 'Subtotal price',
  'Successful placed order':
    'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.',
  'Terms and conditions': 'Terms and conditions',
  Total: 'Total',
  'Order Total': 'Order Total',
  'Total items': 'Total items',
  'Total price': 'Total price',
  'Use your personal data':
    'At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the',
  'User Account': 'User Account',
  View: 'View',
  'View details': 'View details',
  'Vue Storefront Next': 'Vue Storefront Next',
  'Who we are': 'Who we are',
  'Women fashion': 'Women fashion',
  'You added {product} to your shopping cart.': 'You added {product} to your shopping cart.',
  'You are not authorized, please log in': 'You are not authorized, please log in',
  'You haven’t searched for items yet': 'You haven’t searched for items yet.',
  'Your current email address is': 'Your current email address is',
  'Your email': 'Your email',
  'The email field must be a valid email': 'The email field must be a valid email',
  'You have submitted no reviews': 'You have submitted no reviews',
  'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.':
    'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.',
  'A customer with the same email address already exists in an associated website.':
    'A customer with the same email address already exists in an associated website.',
  'SUMMER COLLECTION {year}': 'SUMMER COLLECTION {year}',
  'Colorful summer dresses are already in store': 'Colorful summer dresses are already in store',
  'Learn more': 'Learn more',
  Dresses: 'Dresses',
  'Cocktail & Party': 'Cocktail & Party',
  'Linen Dresses': 'Linen Dresses',
  'T-Shirts': 'T-Shirts',
  'Shop now': 'Shop now',
  'Summer Sandals': 'Summer Sandals',
  'Eco Sandals': 'Eco Sandals',
  'Subscribe to Newsletters': 'Subscribe to Newsletters',
  'Be aware of upcoming sales and events. Receive gifts and special offers!':
    'Be aware of upcoming sales and events. Receive gifts and special offers',
  'Fashion to take away': 'Fashion to take away',
  HasRemoveCouponFirst: 'Has removed the current code, is trying applying a new one ...',
  'Download our application to your mobile': 'Download our application to your mobile',
  'Share Your Look': 'Share Your Look',
  'Go To Product': 'Go To Product',
  'Change to list view': 'Change to list view',
  'Change to grid view': 'Change to grid view',
  Returns: 'Returns',
  'My orders': 'My orders',
  'New Password': 'New Password',
  'New Products': 'New Products',
  'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.':
    'There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.',
  "We can't find products matching the selection.": "We can't find products matching the selection.",
  'Back to Home page': 'Back to Home page',
  'An error occurred': 'An error occurred',
  AllProductsFromCategory: 'All {categoryName}',
  'Show more': 'Show more',
  'Show less': 'Show less',
  Yes: 'Yes',
  No: 'No',
  "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
  From: 'From',
  To: 'To',
  'Your customization': 'Your customization',
  "Passwords don't match": "Passwords don't match",
  'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)':
    'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)',
  'Show all products': 'Show all products',
  'Select previously saved address': 'Select previously saved address',
  'Enter different address': 'Enter different address',
  'You must confirm your account. Please check your email for the confirmation link.':
    'You must confirm your account. Please check your email for the confirmation link.',
  'Change Store': 'Change Store',
  'Choose Currency': 'Choose Currency',
  'Add a review': 'Add a review',
  Title: 'Title',
  Name: 'Name',
  Review: 'Review',
  'Add review': 'Add review',
  'Are you sure you would like to remove this item from the shopping cart?':
    'Are you sure you would like to remove this item from the shopping cart?',
  'Are you sure?': 'Are you sure?',
  '{0} has been successfully removed from your cart': '{0} has been successfully removed from your cart',
  Amount: 'Amount',
  'Your Purchase': 'Your Purchase',
  'Primary contacts for any questions': 'Primary contacts for any questions',
  'Your Account': 'Your account',
  'What can we improve': 'What can we improve',
  'Payment date': 'Payment date',
  'The user password was changed successfully updated!': 'The user password was changed successfully updated!',
  'The user account data was successfully updated!': 'The user account data was successfully updated!',
  'You submitted your review for moderation.': 'You submitted your review for moderation.',
  'Starting at': 'Starting at',
  ResetPasswordDescription: 'Password must be 8 characters (One uppercase and one lowercase and one symbol)',
  addSimpleProductsToCartUnknownError: 'Sorry, failed to add this product, please try it again.',
  'Out of stock': 'Out of stock',
  'Delivery time': 'Delivery time',
  '15-21 working days': '15-21 working days',
  'Please remove the sold out products from your  cart': 'Please remove the sold out products from your  cart',
  'My Acount': 'My Acount',
  Wishlist: 'Wishlist',
  Currency: 'Currency',
  'Your wish list is empty': 'Your wish list is empty',
  CartLoadError: 'Sorry, load your product list failed, please refresh page again.',
  'Billing address and shipping address are the same': 'Billing address and shipping address are the same',
  'Save Billing Address': 'Save Billing Address',
  NoAvailableShippingMethod: 'There are no shipping methods available for you right now. Please try again or use an alternative payment method.',
  'Express Checkout': 'Express Checkout',
  'Checkout With': 'Checkout With',
  RefreshPage: 'Please refresh your page and try it again',
  'Your Cart': 'Your Cart',
  'or Express Checkout with': 'or Express Checkout with',
  'Please Click to start your payment': 'Please Click to start your payment',
  EmptyCart: 'Ouhh...it’s empty in here!',
  AddCartMore: 'Add some items and come back...',
  'Cart Not Active': 'Oh your previous cart has expired so now it is empty!',
  Proceed: 'Proceed',
  'Save To AddressBook & Continue': 'Save To AddressBook & Continue',
  'Saved Delivery Addresses': 'Saved Delivery Addresses',
  'A different customized address': 'A different customized address',
  OR: 'OR',
  'Already a customer': 'Already a customer',
  'Basic Details': 'Basic Details',
  'switch to another way': 'switch to another way',
  NihaoPayError: 'Please check your shipping address or choose another payment',
  'Added to your basket!': 'Added to your basket!',
  'track number': 'Track Number',
};
