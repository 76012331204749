import { ComposableFunctionArgs } from '~/composables';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { UseContextReturn } from '~/types/core';

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<{ order: PlaceOrderOutput | null, errors: any }> => {
    const { data, errors } = await context.app.$vsf.$magento.api.placeOrder(
      { cart_id: cartId },
      params?.customQuery ?? null,
      params?.customHeaders,
    );

    return {
      order: data?.placeOrder ?? null,
      errors,
    };
  },
};
