import { Middleware } from '@nuxt/types';

const middleware : Middleware = (context) => {
  if (!context.app.$vsf.$magento.config.state.getCustomerToken()) {
    context.app.$vsf.$magento.config.state.setMessage({
      id: Symbol('no_login_info'),
      message: context.app.i18n.t('Please log in firstly') as string,
      persist: false,
      type: 'info',
      icon: 'info',
    });
    setTimeout(() => {
      context.app.router.push(context.app.localePath('/user/signin'));
      // const homepage = context.localeRoute({ name: 'SignInPage' });
      // context.redirect(homepage);
    }, 800);
  }
};
export default middleware;
