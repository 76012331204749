// @ts-nocheck
import type { ApolloQueryResult } from '@apollo/client/core/types';
import type { Plugin } from '@nuxt/types';

import type { UiNotification } from '~/composables/useUiNotification';
import { useCustomerStore } from '~/modules/account/stores/customer';

export const hasGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) => res?.errors
  ?.some((error) => error?.extensions?.category === 'graphql-authorization') ?? false;

const plugin: Plugin = ({ $pinia, app }) => {
  const customerStore = useCustomerStore($pinia);
  if (app.$vsf.$magento.config.state.getCustomerToken()) {
    customerStore.setIsLoggedIn(true);
  }

  app.$vsf.$magento.client.interceptors.response.use((res) => {
    if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
      return res;
    }
    customerStore.setIsLoggedIn(false);
    customerStore.setUser(null);
    app.$vsf.$magento.config.state.removeCustomerToken();
    app.$vsf.$magento.config.state.removeCartId();
    app.$vsf.$magento.config.state.setMessage<UiNotification>({
      id: Symbol('login-expired'),
      message: app.i18n.t('You are not authorized or your login data has expired, please log in again.') as string,
      type: 'danger',
      icon: null,
      persist: false,
      title: null,
    });

    app.router.push(app.localePath('/user/signin'));

    return res;
  });
};

export default plugin;
