




































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useRoute,
} from '@nuxtjs/composition-api';

// import LazyHydrate from 'vue-lazy-hydration';
import IconSprite from '~/components/General/IconSprite.vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    // LazyHydrate,
    IconSprite,
    TopShippingCarousel: () =>
      process.client
        ? import(
            /* webpackPrefetch: true */ '~/components/TopShippingCarousel.vue'
          )
        : null,
    CNTopShippingCarousel: () =>
      process.client
        ? import(
            /* webpackPrefetch: true */ '~/components/CNTopShippingCarousel.vue'
          )
        : null,
    AppHeader: () =>
      import(/* webpackPrefetch: true */ '~/components/AppHeader.vue'),
    TopBar: () =>
      process.client ? import('~/components/TopBar/TopBar.vue') : null,
    CookieConsent: () =>
      process.client ? import('~/components/CookieConsent.vue') : null,
    AppFooter: () =>
      process.client
        ? import(/* webpackPrefetch: true */ '~/components/AppFooter.vue')
        : null,
    Notification: () =>
      process.client
        ? import(/* webpackPrefetch: true */ '~/components/Notification.vue')
        : null,
    ReviewsPayMethod: () =>
      process.client
        ? import(
            /* webpackPrefetch: true */ '~/components/Footer/ReviewsPayMethod/ReviewsPayMethod.vue'
          )
        : null,
    Subscription: () =>
      process.client
        ? import(
            /* webpackPrefetch: true */ '~/components/Footer/Subscription/Subscription.vue'
          )
        : null,
    BottomCart: () =>
      process.client
        ? import(/* webpackPrefetch: true */ '~/components/BottomCart.vue')
        : null,
    // eslint-disable-next-line vue/no-unused-components
    ActivityQr: () =>
      process.client
        ? import(/* webpackPrefetch: true */ '~/components/ActivityQr.vue')
        : null,
  },

  setup() {
    const route = useRoute();
    const currentWebSite = ref('');
    const bottomCartVisible = computed(
      () => route.value?.path.indexOf('/cart') < 0
    );

    onMounted(() => {
      const {
        $config: { currentSite },
      } = useContext();
      currentWebSite.value = currentSite;
      console.log('[Default.vue:] ==> currentSite:', currentSite);
    });
    return {
      bottomCartVisible,
      route,
      currentWebSite,
    };
  },
});
