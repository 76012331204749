import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3cf4e9c6 = () => interopDefault(import('../modules/account/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _67e46b8b = () => interopDefault(import('../modules/account/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _030836f9 = () => interopDefault(import('../modules/account/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _000163ea = () => interopDefault(import('../modules/account/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _01c127f6 = () => interopDefault(import('../modules/account/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _2f3c9ccb = () => interopDefault(import('../modules/account/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _922cac8e = () => interopDefault(import('../modules/account/pages/MyAccount/MyOverview.vue' /* webpackChunkName: "" */))
const _42f1f747 = () => interopDefault(import('../modules/account/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _23347ec6 = () => interopDefault(import('../modules/account/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _478facac = () => interopDefault(import('../modules/account/pages/MyAccount/MyStoreCredit.vue' /* webpackChunkName: "" */))
const _c18a16d2 = () => interopDefault(import('../modules/account/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _8f050d70 = () => interopDefault(import('../modules/account/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _d63fbc44 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _ac50eeaa = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _ddb742b8 = () => interopDefault(import('../modules/checkout/pages/Checkout-new.vue' /* webpackChunkName: "" */))
const _27a262c6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _4d358908 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _90e710f8 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _6feb273d = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _0bf0faec = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _115548ca = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _478a714b = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _6c973f7e = () => interopDefault(import('../modules/checkout/pages/Checkout/AdyenResult.vue' /* webpackChunkName: "" */))
const _05a3fad2 = () => interopDefault(import('../pages/Sitemap.xml.vue' /* webpackChunkName: "" */))
const _081d0e60 = () => interopDefault(import('../modules/checkout/pages/ThankYou.vue' /* webpackChunkName: "" */))
const _ecf7675a = () => interopDefault(import('../modules/wishlist/pages/Wish.vue' /* webpackChunkName: "" */))
const _13cf06e1 = () => interopDefault(import('../pages/brand/brand-type.ts' /* webpackChunkName: "pages/brand/brand-type" */))
const _84206864 = () => interopDefault(import('../pages/brand/queryBrand.gql.ts' /* webpackChunkName: "pages/brand/queryBrand.gql" */))
const _1618d9c2 = () => interopDefault(import('../pages/user/resetpassword.vue' /* webpackChunkName: "pages/user/resetpassword" */))
const _4b1f15fa = () => interopDefault(import('../pages/user/signin.vue' /* webpackChunkName: "pages/user/signin" */))
const _e9adf874 = () => interopDefault(import('../pages/brand/_id.vue' /* webpackChunkName: "pages/brand/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _3cf4e9c6,
    meta: {"titleLabel":"My Account"},
    name: "account___gb",
    children: [{
      path: "address",
      component: _67e46b8b,
      meta: {"titleLabel":"Addresses Book"},
      name: "account-addresses-details___gb"
    }, {
      path: "my-newsletter",
      component: _030836f9,
      meta: {"titleLabel":"My newsletter"},
      name: "account-my-newsletter___gb"
    }, {
      path: "my-reviews",
      component: _000163ea,
      meta: {"titleLabel":"My reviews"},
      name: "account-my-reviews___gb"
    }, {
      path: "my-wishlist",
      component: _01c127f6,
      meta: {"titleLabel":"My wishlist"},
      name: "account-my-wishlist___gb"
    }, {
      path: "order",
      component: _2f3c9ccb,
      meta: {"titleLabel":"Order history"},
      name: "account-order-history___gb"
    }, {
      path: "overview",
      component: _922cac8e,
      meta: {"titleLabel":"Overview"},
      name: "account-my-overview___gb"
    }, {
      path: "profile",
      component: _42f1f747,
      meta: {"titleLabel":"My profile"},
      name: "account-my-profile___gb"
    }, {
      path: "reset-password",
      component: _23347ec6,
      name: "account-reset-password___gb"
    }, {
      path: "store-credit",
      component: _478facac,
      meta: {"titleLabel":"Store Credit"},
      name: "account-my-store-credit___gb"
    }, {
      path: "addresses-details/create",
      component: _c18a16d2,
      meta: {"titleLabel":"Addresses details"},
      name: "account-addresses-details-new___gb"
    }, {
      path: "order-history/:orderId",
      component: _8f050d70,
      meta: {"titleLabel":"order detail"},
      props: true,
      name: "account-single-order___gb"
    }]
  }, {
    path: "/account",
    component: _3cf4e9c6,
    meta: {"titleLabel":"My Account"},
    name: "account___cn",
    children: [{
      path: "address",
      component: _67e46b8b,
      meta: {"titleLabel":"Addresses Book"},
      name: "account-addresses-details___cn"
    }, {
      path: "my-newsletter",
      component: _030836f9,
      meta: {"titleLabel":"My newsletter"},
      name: "account-my-newsletter___cn"
    }, {
      path: "my-reviews",
      component: _000163ea,
      meta: {"titleLabel":"My reviews"},
      name: "account-my-reviews___cn"
    }, {
      path: "my-wishlist",
      component: _01c127f6,
      meta: {"titleLabel":"My wishlist"},
      name: "account-my-wishlist___cn"
    }, {
      path: "order",
      component: _2f3c9ccb,
      meta: {"titleLabel":"Order history"},
      name: "account-order-history___cn"
    }, {
      path: "overview",
      component: _922cac8e,
      meta: {"titleLabel":"Overview"},
      name: "account-my-overview___cn"
    }, {
      path: "profile",
      component: _42f1f747,
      meta: {"titleLabel":"My profile"},
      name: "account-my-profile___cn"
    }, {
      path: "reset-password",
      component: _23347ec6,
      name: "account-reset-password___cn"
    }, {
      path: "store-credit",
      component: _478facac,
      meta: {"titleLabel":"Store Credit"},
      name: "account-my-store-credit___cn"
    }, {
      path: "addresses-details/create",
      component: _c18a16d2,
      meta: {"titleLabel":"Addresses details"},
      name: "account-addresses-details-new___cn"
    }, {
      path: "order-history/:orderId",
      component: _8f050d70,
      meta: {"titleLabel":"order detail"},
      props: true,
      name: "account-single-order___cn"
    }]
  }, {
    path: "/account",
    component: _3cf4e9c6,
    meta: {"titleLabel":"My Account"},
    name: "account___us",
    children: [{
      path: "address",
      component: _67e46b8b,
      meta: {"titleLabel":"Addresses Book"},
      name: "account-addresses-details___us"
    }, {
      path: "my-newsletter",
      component: _030836f9,
      meta: {"titleLabel":"My newsletter"},
      name: "account-my-newsletter___us"
    }, {
      path: "my-reviews",
      component: _000163ea,
      meta: {"titleLabel":"My reviews"},
      name: "account-my-reviews___us"
    }, {
      path: "my-wishlist",
      component: _01c127f6,
      meta: {"titleLabel":"My wishlist"},
      name: "account-my-wishlist___us"
    }, {
      path: "order",
      component: _2f3c9ccb,
      meta: {"titleLabel":"Order history"},
      name: "account-order-history___us"
    }, {
      path: "overview",
      component: _922cac8e,
      meta: {"titleLabel":"Overview"},
      name: "account-my-overview___us"
    }, {
      path: "profile",
      component: _42f1f747,
      meta: {"titleLabel":"My profile"},
      name: "account-my-profile___us"
    }, {
      path: "reset-password",
      component: _23347ec6,
      name: "account-reset-password___us"
    }, {
      path: "store-credit",
      component: _478facac,
      meta: {"titleLabel":"Store Credit"},
      name: "account-my-store-credit___us"
    }, {
      path: "addresses-details/create",
      component: _c18a16d2,
      meta: {"titleLabel":"Addresses details"},
      name: "account-addresses-details-new___us"
    }, {
      path: "order-history/:orderId",
      component: _8f050d70,
      meta: {"titleLabel":"order detail"},
      props: true,
      name: "account-single-order___us"
    }]
  }, {
    path: "/account",
    component: _3cf4e9c6,
    meta: {"titleLabel":"My Account"},
    name: "account___mex",
    children: [{
      path: "address",
      component: _67e46b8b,
      meta: {"titleLabel":"Addresses Book"},
      name: "account-addresses-details___mex"
    }, {
      path: "my-newsletter",
      component: _030836f9,
      meta: {"titleLabel":"My newsletter"},
      name: "account-my-newsletter___mex"
    }, {
      path: "my-reviews",
      component: _000163ea,
      meta: {"titleLabel":"My reviews"},
      name: "account-my-reviews___mex"
    }, {
      path: "my-wishlist",
      component: _01c127f6,
      meta: {"titleLabel":"My wishlist"},
      name: "account-my-wishlist___mex"
    }, {
      path: "order",
      component: _2f3c9ccb,
      meta: {"titleLabel":"Order history"},
      name: "account-order-history___mex"
    }, {
      path: "overview",
      component: _922cac8e,
      meta: {"titleLabel":"Overview"},
      name: "account-my-overview___mex"
    }, {
      path: "profile",
      component: _42f1f747,
      meta: {"titleLabel":"My profile"},
      name: "account-my-profile___mex"
    }, {
      path: "reset-password",
      component: _23347ec6,
      name: "account-reset-password___mex"
    }, {
      path: "store-credit",
      component: _478facac,
      meta: {"titleLabel":"Store Credit"},
      name: "account-my-store-credit___mex"
    }, {
      path: "addresses-details/create",
      component: _c18a16d2,
      meta: {"titleLabel":"Addresses details"},
      name: "account-addresses-details-new___mex"
    }, {
      path: "order-history/:orderId",
      component: _8f050d70,
      meta: {"titleLabel":"order detail"},
      props: true,
      name: "account-single-order___mex"
    }]
  }, {
    path: "/brand",
    component: _d63fbc44,
    name: "brand___gb"
  }, {
    path: "/brand",
    component: _d63fbc44,
    name: "brand___cn"
  }, {
    path: "/brand",
    component: _d63fbc44,
    name: "brand___us"
  }, {
    path: "/brand",
    component: _d63fbc44,
    name: "brand___mex"
  }, {
    path: "/cart",
    component: _ac50eeaa,
    name: "cart___gb"
  }, {
    path: "/cart",
    component: _ac50eeaa,
    name: "cart___cn"
  }, {
    path: "/cart",
    component: _ac50eeaa,
    name: "cart___us"
  }, {
    path: "/cart",
    component: _ac50eeaa,
    name: "cart___mex"
  }, {
    path: "/checkout",
    component: _ddb742b8,
    name: "checkout___gb",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___gb"
    }, {
      path: "payment",
      component: _4d358908,
      name: "payment___gb"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___gb"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___gb"
    }]
  }, {
    path: "/checkout",
    component: _ddb742b8,
    name: "checkout___cn",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___cn"
    }, {
      path: "payment",
      component: _4d358908,
      name: "payment___cn"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___cn"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___cn"
    }]
  }, {
    path: "/checkout",
    component: _ddb742b8,
    name: "checkout___us",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___us"
    }, {
      path: "payment",
      component: _4d358908,
      name: "payment___us"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___us"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___us"
    }]
  }, {
    path: "/checkout",
    component: _ddb742b8,
    name: "checkout___mex",
    children: [{
      path: "billing",
      component: _27a262c6,
      name: "billing___mex"
    }, {
      path: "payment",
      component: _4d358908,
      name: "payment___mex"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping___mex"
    }, {
      path: "user-account",
      component: _90e710f8,
      name: "user-account___mex"
    }]
  }, {
    path: "/Cms",
    component: _6feb273d,
    name: "Cms___gb"
  }, {
    path: "/Cms",
    component: _6feb273d,
    name: "Cms___cn"
  }, {
    path: "/Cms",
    component: _6feb273d,
    name: "Cms___us"
  }, {
    path: "/Cms",
    component: _6feb273d,
    name: "Cms___mex"
  }, {
    path: "/Faq",
    component: _0bf0faec,
    name: "Faq___gb"
  }, {
    path: "/Faq",
    component: _0bf0faec,
    name: "Faq___cn"
  }, {
    path: "/Faq",
    component: _0bf0faec,
    name: "Faq___us"
  }, {
    path: "/Faq",
    component: _0bf0faec,
    name: "Faq___mex"
  }, {
    path: "/Home",
    component: _115548ca,
    name: "Home___gb"
  }, {
    path: "/Home",
    component: _115548ca,
    name: "Home___cn"
  }, {
    path: "/Home",
    component: _115548ca,
    name: "Home___us"
  }, {
    path: "/Home",
    component: _115548ca,
    name: "Home___mex"
  }, {
    path: "/Page",
    component: _478a714b,
    name: "Page___gb"
  }, {
    path: "/Page",
    component: _478a714b,
    name: "Page___cn"
  }, {
    path: "/Page",
    component: _478a714b,
    name: "Page___us"
  }, {
    path: "/Page",
    component: _478a714b,
    name: "Page___mex"
  }, {
    path: "/pay-result",
    component: _6c973f7e,
    name: "pay-result___gb"
  }, {
    path: "/pay-result",
    component: _6c973f7e,
    name: "pay-result___cn"
  }, {
    path: "/pay-result",
    component: _6c973f7e,
    name: "pay-result___us"
  }, {
    path: "/pay-result",
    component: _6c973f7e,
    name: "pay-result___mex"
  }, {
    path: "/sitemap.xml",
    component: _05a3fad2,
    name: "sitemap.xml___gb"
  }, {
    path: "/sitemap.xml",
    component: _05a3fad2,
    name: "sitemap.xml___cn"
  }, {
    path: "/sitemap.xml",
    component: _05a3fad2,
    name: "sitemap.xml___us"
  }, {
    path: "/sitemap.xml",
    component: _05a3fad2,
    name: "sitemap.xml___mex"
  }, {
    path: "/Sitemap.xml",
    component: _05a3fad2,
    name: "Sitemap.xml___gb"
  }, {
    path: "/Sitemap.xml",
    component: _05a3fad2,
    name: "Sitemap.xml___cn"
  }, {
    path: "/Sitemap.xml",
    component: _05a3fad2,
    name: "Sitemap.xml___us"
  }, {
    path: "/Sitemap.xml",
    component: _05a3fad2,
    name: "Sitemap.xml___mex"
  }, {
    path: "/thank-you",
    component: _081d0e60,
    name: "thank-you___gb"
  }, {
    path: "/thank-you",
    component: _081d0e60,
    name: "thank-you___cn"
  }, {
    path: "/thank-you",
    component: _081d0e60,
    name: "thank-you___us"
  }, {
    path: "/thank-you",
    component: _081d0e60,
    name: "thank-you___mex"
  }, {
    path: "/wish",
    component: _ecf7675a,
    name: "wish___gb"
  }, {
    path: "/wish",
    component: _ecf7675a,
    name: "wish___cn"
  }, {
    path: "/wish",
    component: _ecf7675a,
    name: "wish___us"
  }, {
    path: "/wish",
    component: _ecf7675a,
    name: "wish___mex"
  }, {
    path: "/brand/brand-type",
    component: _13cf06e1,
    name: "brand-brand-type___gb"
  }, {
    path: "/brand/brand-type",
    component: _13cf06e1,
    name: "brand-brand-type___cn"
  }, {
    path: "/brand/brand-type",
    component: _13cf06e1,
    name: "brand-brand-type___us"
  }, {
    path: "/brand/brand-type",
    component: _13cf06e1,
    name: "brand-brand-type___mex"
  }, {
    path: "/brand/queryBrand.gql",
    component: _84206864,
    name: "brand-queryBrand.gql___gb"
  }, {
    path: "/brand/queryBrand.gql",
    component: _84206864,
    name: "brand-queryBrand.gql___cn"
  }, {
    path: "/brand/queryBrand.gql",
    component: _84206864,
    name: "brand-queryBrand.gql___us"
  }, {
    path: "/brand/queryBrand.gql",
    component: _84206864,
    name: "brand-queryBrand.gql___mex"
  }, {
    path: "/user/resetpassword",
    component: _1618d9c2,
    name: "user-resetpassword___gb"
  }, {
    path: "/user/resetpassword",
    component: _1618d9c2,
    name: "user-resetpassword___cn"
  }, {
    path: "/user/resetpassword",
    component: _1618d9c2,
    name: "user-resetpassword___us"
  }, {
    path: "/user/resetpassword",
    component: _1618d9c2,
    name: "user-resetpassword___mex"
  }, {
    path: "/user/signin",
    component: _4b1f15fa,
    name: "user-signin___gb"
  }, {
    path: "/user/signin",
    component: _4b1f15fa,
    name: "user-signin___cn"
  }, {
    path: "/user/signin",
    component: _4b1f15fa,
    name: "user-signin___us"
  }, {
    path: "/user/signin",
    component: _4b1f15fa,
    name: "user-signin___mex"
  }, {
    path: "/brand/:id",
    component: _e9adf874,
    name: "brand-id___gb"
  }, {
    path: "/brand/:id",
    component: _e9adf874,
    name: "brand-id___cn"
  }, {
    path: "/brand/:id",
    component: _e9adf874,
    name: "brand-id___us"
  }, {
    path: "/brand/:id",
    component: _e9adf874,
    name: "brand-id___mex"
  }, {
    path: "/",
    component: _115548ca,
    name: "home___gb"
  }, {
    path: "/",
    component: _115548ca,
    name: "home___cn"
  }, {
    path: "/",
    component: _115548ca,
    name: "home___us"
  }, {
    path: "/",
    component: _115548ca,
    name: "home___mex"
  }, {
    path: "/:slug+",
    component: _478a714b,
    name: "page___gb"
  }, {
    path: "/:slug+",
    component: _478a714b,
    name: "page___cn"
  }, {
    path: "/:slug+",
    component: _478a714b,
    name: "page___us"
  }, {
    path: "/:slug+",
    component: _478a714b,
    name: "page___mex"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
