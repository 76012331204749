import Clipboard from 'clipboard';
import { Plugin } from '@nuxt/types';

declare module 'vue/types/vue' {
  interface Vue {
    $clipboard(text: string): string;
  }
}

const plugin: Plugin = (_, inject) => {
  inject('clipboard', (text: string) => Clipboard.copy(text));
};

export default plugin;
