import { UseContextReturn } from '~/types/core';
import type { SubscriptionStatusesEnum } from '~/modules/GraphQL/types';
import type { UseNewsletterUpdateSubscriptionParams } from '~/composables';

export const updateSubscriptionCommand = {
  execute: async (context: UseContextReturn, params: UseNewsletterUpdateSubscriptionParams) => {
    const { data, errors } = await context.app.$vsf.$magento.api.subscribeEmailToNewsletter({
      email: params.email,
    }, params?.customQuery ?? null, params?.customHeaders ?? null);

    return {
      result: data?.subscribeEmailToNewsletter?.status ?? null,
      errors,
    };
  },
};
