












import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import type { CategoryTree } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'HeaderNavigationItem',
  components: {
    SfLink,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    uid: {
      type: String || null,
      default: '',
    },
    currentCategory: {
      type: Object as PropType<CategoryTree | null>,
      default: () => null,
    },
  },
});
